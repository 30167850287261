import React           from 'react';
import { ChartLegend } from '../../../../../lib/components/charts/legend';
import { ChartPie }    from '../../../../../lib/components/charts/radial-chart.component';
import { Title }       from '../../../../../lib/components/title/title';
import { Wrapper }     from '../../../../../lib/components/wrapper/wrapper.component';
import { colors }      from '../../../../../lib/styles/color';

const data = [
    { name: 'Staff expense', value: 77 },
    { name: 'Program expense', value: 12, color: colors.red.hex() },
    { name: 'Utilities', value: 4, color: colors.yellow.hex() },
    { name: 'Equipment & software', value: 1, color: colors.yellow.hex() },
    { name: 'Other', value: 4, color: colors.gray.darken(0.2).hex() }
];

const Chart2 = () => {
    const getColor = (index) => colors.key.lighten(0.03 * index).rotate(-5 * index).hex();

    return (
        <Wrapper frame="solid-grey" radius="md" row columns={ 2 } padding="xxl" margin="md" xsm={ { columns: 1 } }>
            <Wrapper style={ { height: '100%' } }>
                <Title margin="lg">
                    Share of total income Per Forecast 3, %
                </Title>
                <Wrapper flex={ 1 } style={ { height: 24 } } xsm={ { hide: true } } />
                <Wrapper>
                    <ChartLegend data={ data } colorFunction={ (index) => getColor(index) } />
                </Wrapper>
            </Wrapper>
            <Wrapper>
                <ChartPie
                    data={ data }
                    colorFunction={ (index) => getColor(index) }
                    tooltip
                />
            </Wrapper>
        </Wrapper>
    );
};

export default Chart2;
